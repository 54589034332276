import { useRouter } from 'next/router';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filtersCounter, getFilteredItemsOptions } from '@/redux/actions';
import { deviceWidth } from '@/utils/deviceWidth';
import { DEFAULT_FILTER_COUNT_VALUE, MAX_MOBILE_WIDTH } from '@/utils/constants';
import { CloseIconGrey } from '@/assets/svgExports/CloseIconGrey';
import FilterComponent from '../FilterComponent/FilterComponent';
import {
  ClearAllCta,
  FilterDrawerContainer,
  FilterDrawerDesktop,
  FilterHeading,
  FilterHeadingText,
} from './FilterDrawer.styles';

function FilterDrawer(props) {
  const dispatch = useDispatch();
  const router = useRouter();
  const isMobile = deviceWidth <= MAX_MOBILE_WIDTH;
  const { handleShowFilterDrawer } = props;
  const filterDrawerRef = useRef();
  const filterHeadingRef = useRef(null);
  const [filterHeadingHeight, setFilterHeadingHeight] = useState(0);

  const { headerHeight, sortType, storeData, filterOptionLoader } = useSelector(
    (state) => ({
      headerHeight: state.commonReducer?.headerRef?.current?.clientHeight ?? 0,
      sortType: state.catalogReducer.sortType,
      storeData: state.storeReducer.store,
      filterOptionLoader: state.commonReducer.filterOptionLoader,
    })
  );

  useEffect(() => {
    if (filterHeadingRef.current) {
      setFilterHeadingHeight(filterHeadingRef.current.clientHeight);
    }
  }, []);

  useEffect(() => {
    if (!isMobile) {
      const styleOverflowHidden = () => {
        document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
      };
      const styleOverflowAuto = () => {
        document.getElementsByTagName('body')[0].style.overflowY = 'auto';
      };

      if (filterDrawerRef.current) {
        filterDrawerRef.current.addEventListener('mouseover', styleOverflowHidden);
        filterDrawerRef.current.addEventListener('mouseout', styleOverflowAuto);

        return () => {
          filterDrawerRef.current.removeEventListner('mouseover', styleOverflowHidden);
          filterDrawerRef.current.removeEventListner('mouseout', styleOverflowAuto);
        };
      }
    }
  }, []);

  const FilterDrawerTopMargin = headerHeight + filterHeadingHeight;

  /**
   * resets the filterOptions = [] and filtereditems = []
   * and filteredApplied counter goes to 0
   * reset payload api gets called again
   */

  function handleClearAll() {
    dispatch(filtersCounter(DEFAULT_FILTER_COUNT_VALUE, true));
    if (router?.query?.cid || router?.query?.collectionId) {
      router.reload(router?.asPath);
      return;
    }
    const payload = {
      store_id: storeData?.store_id,
      page_no: 1,
      sorting_type: sortType,
      filters: [],
    };
    dispatch(getFilteredItemsOptions(payload));
  }

  function renderFilterDrawerHeading() {
    return (
      <FilterHeading ref={filterHeadingRef}>
        <FilterHeadingText>Filters</FilterHeadingText>
        <ClearAllCta onClick={handleClearAll}>
          <span className="pointer" style={{ whiteSpace: 'nowrap' }}>
            Clear All
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              handleShowFilterDrawer();
            }}
            className="ml12px flex align-center pointer"
          >
            <CloseIconGrey />
          </span>
        </ClearAllCta>
      </FilterHeading>
    );
  }

  function renderFilterDrawerDesktop() {
    return (
      <FilterDrawerDesktop
        ref={filterDrawerRef}
        loader={filterOptionLoader}
        headerHeight={FilterDrawerTopMargin}
      >
        <FilterComponent
          hideCollectionFilter={props.hideCollectionFilter}
          hideCategoryFilter={props.hideCategoryFilter}
          hideTags={props.hideTags}
        />
      </FilterDrawerDesktop>
    );
  }

  function renderFilterDrawerMobile() {
    return (
      <FilterComponent
        hideCollectionFilter={props.hideCollectionFilter}
        hideCategoryFilter={props.hideCategoryFilter}
      />
    );
  }

  return (
    <FilterDrawerContainer>
      {renderFilterDrawerHeading()}
      {isMobile ? renderFilterDrawerMobile() : renderFilterDrawerDesktop()}
    </FilterDrawerContainer>
  );
}

export default FilterDrawer;
